import React from 'react';

import debounce from 'lodash.debounce';

import { IconImage, LoadingSpinnerIcon } from '@/core/features';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { useAtomValue } from '@/core/features/store/atom-store';
import {
    useLayerSwitchOverlayOpen,
    useMapLoadingState,
    useMapTravelFormLoading,
} from '@/features/map/map-container/map-state';
import { useAutoLoadMoreSetting } from '@/features/map/map-data-v5/auto-load-more-button-state';
import {
    isMapDataV5ByBoundariesLoadingAtom,
    useLoadMoreButtonLoading,
} from '@/features/map/map-data-v5/use-map-data-v5';

import styles from './map-load-more-button.module.scss';

type MapLoadMoreButtonProps = {
    iconUrl: string;
    onLoadMore: () => void;
    title: string;
};

export default function MapLoadMoreButton({ iconUrl, onLoadMore, title }: MapLoadMoreButtonProps) {
    const { isDesktopView } = useDeviceoutput();
    const isLoadMoreButtonLoading = useLoadMoreButtonLoading();
    const isMapLoading = useMapLoadingState();
    const [isLayerSwitchOverlayOpen] = useLayerSwitchOverlayOpen();
    const isMapTravelFormLoading = useMapTravelFormLoading();
    const isMapDataByBoundariesLoading = useAtomValue(isMapDataV5ByBoundariesLoadingAtom);

    const isAutoLoadMoreFeatureFlagEnabled = useAutoLoadMoreSetting();

    const onClickLoadMore = () => {
        if (isLoadMoreButtonLoading || isMapLoading) {
            return;
        }
        onLoadMore();
    };

    React.useEffect(() => {
        if (!isAutoLoadMoreFeatureFlagEnabled || isMapDataByBoundariesLoading) {
            return;
        }

        const debouncedOnLoadMore = debounce(onLoadMore, 100);
        debouncedOnLoadMore();

        return () => {
            debouncedOnLoadMore.cancel();
        };
    }, [isAutoLoadMoreFeatureFlagEnabled, isLoadMoreButtonLoading, isMapDataByBoundariesLoading, onLoadMore]);

    if (!isLoadMoreButtonLoading && isAutoLoadMoreFeatureFlagEnabled) {
        return null;
    }

    if (isLayerSwitchOverlayOpen || isMapTravelFormLoading) {
        return null;
    }

    const iconSize = isDesktopView ? 15 : 11;

    return (
        <div className={'flex-center'}>
            <button
                className={`flex-center ${styles.loadMoreButton}`}
                data-qa-id={'qa-map-load-more-button'}
                onClick={onClickLoadMore}
                style={{ opacity: isLoadMoreButtonLoading ? 0.7 : 1 }}
            >
                {isLoadMoreButtonLoading ? (
                    <LoadingSpinnerIcon
                        height={iconSize}
                        width={iconSize}
                    />
                ) : (
                    <IconImage
                        height={iconSize}
                        url={iconUrl}
                        width={iconSize}
                    />
                )}
                {isAutoLoadMoreFeatureFlagEnabled ? 'Unterkünfte werden geladen' : title}
            </button>
        </div>
    );
}
