import type { GoogleMapLatLngLiteral } from '@/features/map/google-map/google-map-types';
import type { IMapMarker, IMapPinsRecord, IMapPoiIconRecord } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import CustomGoogleOverlay from '@/features/map/google-map/google-map-custom-overlay';
import { useGoogleMapInstance } from '@/features/map/google-map/google-map-state';
import { ACCOMMODATION_MARKER_HEIGHT } from '@/features/map/map-components/map-markers/accommodation-marker/accommodation-marker';
import MapClusterSwitch from '@/features/map/map-components/map-markers/map-marker-handler/map-cluster-switch/map-cluster-switch';
import MapMarkerSwitch from '@/features/map/map-components/map-markers/map-marker-handler/map-marker-switch/map-marker-switch';
import {
    useMapActivePoiKeyState,
    useMapMultiPinState,
    useMapTravelFormLoading,
    useMapVisitedPois,
} from '@/features/map/map-container/map-state';

type MapMarkerHandlerProps = {
    icons: IMapPoiIconRecord | null;
    isDesktopView?: boolean;
    onClusterClick: (point: GoogleMapLatLngLiteral) => void;
    onPoiClick: (event: React.MouseEvent, poiKey: string) => void;
    pins: IMapPinsRecord;
};

export default function MapMarkerHandler({
    icons,
    isDesktopView = false,
    onClusterClick,
    onPoiClick,
    pins,
}: MapMarkerHandlerProps) {
    const googleMapInstance = useGoogleMapInstance();
    const [visitedPois] = useMapVisitedPois();
    const activePoiKey = useMapActivePoiKeyState();
    const activeMultiPinKey = useMapMultiPinState();
    const isAccommodationPinsHidden = useMapTravelFormLoading();

    const pinEntries = React.useMemo(() => Object.entries(pins), [pins]);

    if (!googleMapInstance) {
        return null;
    }

    return (
        <>
            {pinEntries.map(([markerKey, marker]) => {
                if (marker.type === 'accommodation' && isAccommodationPinsHidden) {
                    return;
                }

                const point: GoogleMapLatLngLiteral = { lat: marker.point.latitude, lng: marker.point.longitude };

                const isActivePoi = activePoiKey === markerKey || activeMultiPinKey === markerKey;

                return (
                    <CustomGoogleOverlay
                        disableHighlighting={!!activePoiKey}
                        isActive={isActivePoi}
                        key={markerKey}
                        map={googleMapInstance}
                        markerType={marker.markerType}
                        offsetTop={getMarkerOffsetTop(marker, icons)}
                        poiType={marker.type}
                        position={{ lat: point.lat, lng: point.lng }}
                        zIndex={marker.zPosition}
                    >
                        <div
                            className={
                                'flex align-center gap-5 relative pointer-auto text-left opacity-100 user-select-none cursor-default'
                            }
                        >
                            {marker.markerType === 'indicator' || marker.markerType === 'cluster' ? (
                                <MapClusterSwitch
                                    icons={icons}
                                    isActive={isActivePoi}
                                    marker={marker}
                                    onClusterClick={onClusterClick}
                                    point={point}
                                />
                            ) : (
                                <MapMarkerSwitch
                                    activePoiKey={activePoiKey}
                                    icons={icons}
                                    isActive={isActivePoi}
                                    isDesktopView={isDesktopView}
                                    marker={marker}
                                    markerKey={markerKey}
                                    onPoiClick={onPoiClick}
                                    visited={visitedPois.includes(markerKey)}
                                />
                            )}
                        </div>
                    </CustomGoogleOverlay>
                );
            })}
        </>
    );
}

const getMarkerOffsetTop = (marker: IMapMarker, icons: IMapPoiIconRecord | null): number => {
    if (marker.markerType === 'indicator') {
        return 4;
    }

    if (marker.markerType === 'multi') {
        const icon = Object.values(marker.pins)[0]?.pinIcon;
        return icon && icons ? (icons[icon]?.active.size ?? 0) : 0;
    }

    switch (marker.type) {
        case 'place':
        case 'school':
        case 'spot':
            return icons?.[marker.pinIcon]?.active?.size ?? 0;
        case 'accommodation':
            return ACCOMMODATION_MARKER_HEIGHT;
    }
};
