import LayersIcon from '@/assets/icons/layers';
import { FullscreenOverlay } from '@/core/features';
import { getCdnStaticImageUrl } from '@/core/features/image-v2/image-utils';
import color from '@/core/features/styles/color';
import MapLayerSelectionOption from '@/features/map/map-components/map-layer-switch/map-layer-switch-option';
import { defaultMapType } from '@/features/map/map-components/map-layer-switch/map-layer-switch-state';
import { useLayerSwitchOverlayOpen } from '@/features/map/map-container/map-state';

import styles from './map-layer-switch.module.scss';

export const mapLayerOptions = {
    hybrid: {
        imageUrl: getCdnStaticImageUrl('/static-images/google-map-selection-satelite-7.webp'),
        label: 'Satellit',
        type: 'hybrid',
    },
    roadmap: {
        imageUrl: getCdnStaticImageUrl('/static-images/google-map-selection-hybrid-7.webp'),
        label: 'Standard',
        type: 'roadmap',
    },
    terrain: {
        imageUrl: getCdnStaticImageUrl('/static-images/google-map-selection-terrain-4.webp'),
        label: 'Gelände',
        type: 'terrain',
    },
} as const;
export type MapType = keyof typeof mapLayerOptions;

const mapTypeSelection = ['roadmap', 'hybrid', 'terrain'] as const;

interface MapLayerSwitchProps {
    activeMapType: MapType;
    onOptionClick: (type: MapType) => void;
}

export default function MapLayerSwitch({ activeMapType, onOptionClick }: MapLayerSwitchProps) {
    const [isLayerSwitchOverlayOpen, setLayerSwitchOverlayOpen] = useLayerSwitchOverlayOpen();
    return (
        <>
            <div
                className={`flex-center bg-white ${styles.layerSwitch} pointer`}
                data-qa-id={'qa-map-layer-switch'}
                onClick={() => setLayerSwitchOverlayOpen(true)}
            >
                <LayersIcon
                    color={activeMapType !== defaultMapType ? color('endeavour') : color('mineShaft')}
                    height={20}
                    width={20}
                />
            </div>
            {isLayerSwitchOverlayOpen && (
                <Overlay
                    activeMapTypeId={activeMapType}
                    onClose={() => setLayerSwitchOverlayOpen(false)}
                    onOptionClick={onOptionClick}
                    title={'Kartentyp wählen'}
                />
            )}
        </>
    );
}

const Overlay = ({
    activeMapTypeId,
    onClose,
    onOptionClick,
    title,
}: {
    activeMapTypeId: MapType;
    onClose: () => void;
    onOptionClick: (key: MapType) => void;
    title: string;
}) => {
    return (
        <FullscreenOverlay
            backgroundTheme={'light'}
            onClose={onClose}
        >
            <div className={styles.overlay}>
                <div className={styles.layerSelection}>
                    <div className={'font-size-14'}>{title}</div>
                    <div className={styles.selectOptions}>
                        {mapTypeSelection.map((type) => {
                            const mapType = mapLayerOptions[type];

                            return (
                                <MapLayerSelectionOption
                                    active={activeMapTypeId === type}
                                    imageUrl={mapType.imageUrl}
                                    key={type}
                                    label={mapType.label}
                                    onOptionClick={() => onOptionClick(mapType.type)}
                                />
                            );
                        })}
                    </div>
                </div>
                <button
                    className={styles.closeButton}
                    data-qa-id={'qa-map-layer-switch-close'}
                    onClick={onClose}
                >
                    schließen
                </button>
            </div>
        </FullscreenOverlay>
    );
};
