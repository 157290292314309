import type { IMapPoiIcon } from '@/features/map/map-data-v5/map-types';

import { IconImage } from '@/core/features';

interface PinClusterMarkerProps {
    icon: IMapPoiIcon;
    onClick: () => void;
    text: string;
}

export default function PoiClusterMarker({ icon, onClick, text }: PinClusterMarkerProps) {
    const { font, size, url } = icon;

    // should not happen
    if (!size || !url || !font) {
        return null;
    }

    return (
        <div
            className={'flex-center pointer relative'}
            data-qa-id={'qa-map-cluster-marker'}
            onClick={onClick}
        >
            <IconImage
                height={size}
                url={url}
                width={size}
            />
            <div
                className={'flex-center z-index-1 bold border-radius-circle padding-2 absolute bg-color-foam'}
                style={{
                    color: font.color,
                    fontSize: font.size,
                    height: size / 2,
                    right: -3,
                    top: -5,
                    width: size / 2,
                }}
            >
                {text}
            </div>
        </div>
    );
}
