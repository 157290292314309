import type { VerticalToggleOptionMap } from '@/features/vertical-toggle/vertical-toggle-type';

import React from 'react';

import { useClientUrl } from '@/core/features/app/app-atoms';
import IconImage from '@/core/features/image-v2/icon-image';
import { historyReplaceDebounced } from '@/core/features/router/history';
import MapTooltip from '@/features/map/map-components/map-tooltip/map-tooltip';

import styles from './map-vertical-toggle.module.scss';

type MapVerticalToggleProps = {
    isOpen: boolean;
    onToggle: () => void;
    verticalToggleIconUrl: string;
    verticalToggleOptions: VerticalToggleOptionMap[];
};

export default function MapVerticalToggle({
    isOpen,
    onToggle,
    verticalToggleIconUrl,
    verticalToggleOptions,
}: MapVerticalToggleProps) {
    const [_clientUrl, setClientUrl] = useClientUrl();

    const onOptionClick = (option: VerticalToggleOptionMap) => {
        historyReplaceDebounced(option.linkUrl);
        setClientUrl(option.linkUrl);
    };

    return (
        <div
            className={`flex-center bg-white pointer ${styles.container}`}
            data-qa-id={'qa-map-vertical-toggle'}
            onClick={onToggle}
        >
            <div className={`flex-center ${styles.icon}`}>
                <IconImage
                    height={20}
                    url={verticalToggleIconUrl}
                    width={20}
                />
            </div>
            {isOpen && (
                <MapTooltip
                    noOptionsText={null}
                    onOptionClick={onOptionClick}
                    options={verticalToggleOptions}
                />
            )}
        </div>
    );
}
