import type { RefObject } from 'react';

import ChevronSolidIcon from '@/assets/icons/chevron-solid';
import color from '@/core/features/styles/color';
type AccommodationMarkerProps = {
    isActive: boolean;
    markerRef?: RefObject<HTMLDivElement>;
    onPoiClick: (event: React.MouseEvent) => void;
    price: null | string;
    visited: boolean;
};

export const ACCOMMODATION_MARKER_HEIGHT = 26;

export default function AccommodationMarker({
    isActive,
    markerRef,
    onPoiClick,
    price,
    visited,
}: AccommodationMarkerProps) {
    const backgroundColor = isActive ? color('denim') : visited ? color('mineShaft') : color('catalinaBlue');

    const style = {
        backgroundColor,
        borderRadius: 25,
        marginBottom: -2,
        padding: '3px 6px',
    };

    return (
        <div
            className={'pointer'}
            data-qa-id={'qa-map-marker-accommodation'}
            onClick={onPoiClick}
            ref={markerRef}
            style={{ height: ACCOMMODATION_MARKER_HEIGHT }}
        >
            <div
                className={'bold font-size-12 text-white'}
                style={style}
            >
                {price}
            </div>
            <ChevronSolidIcon
                color={backgroundColor}
                height={8}
                width={12}
            />
        </div>
    );
}
