import type { IMapPoiIcon } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import { IconImage } from '@/core/features';

export interface DefaultPoiMarkerProps {
    icon: IMapPoiIcon | null;
    markerRef?: React.RefObject<HTMLDivElement>;
    onClick: (event: React.MouseEvent) => void;
    qaId: string;
    secondaryIcon?: IMapPoiIcon | null;
}

export default function DefaultPoiMarker({ icon, markerRef, onClick, qaId, secondaryIcon }: DefaultPoiMarkerProps) {
    if (!icon) {
        return null;
    }

    return (
        <div
            className={'flex-center pointer'}
            data-qa-id={qaId}
            onClick={onClick}
            ref={markerRef}
        >
            <IconImage
                height={icon.size}
                url={icon.url}
                width={icon.size}
            />
            {secondaryIcon && (
                <div
                    className={'absolute'}
                    style={{ right: -0, top: -8 }}
                >
                    <IconImage
                        height={secondaryIcon.size}
                        url={secondaryIcon.url}
                        width={secondaryIcon.size}
                    />
                </div>
            )}
        </div>
    );
}
