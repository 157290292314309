import type { MapType } from '@/features/map/map-components/map-layer-switch/map-layer-switch';

import LocalStorage from '@/core/features/store/local-storage';

const mapTypeStorage = new LocalStorage<MapType>('map_type', { defaultKey: '' });

export const getStoredMapType = (): MapType => {
    return mapTypeStorage.get() ?? defaultMapType;
};

export const setStoredMapType = (mapType: MapType): void => {
    mapTypeStorage.set(mapType);
};

export const defaultMapType: MapType = 'roadmap';
