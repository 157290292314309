import React from 'react';

import ChevronSolidIcon from '@/assets/icons/chevron-solid';
import { IconImage } from '@/core/features';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { getCdnStaticImageUrl } from '@/core/features/image-v2/image-utils';
import color from '@/core/features/styles/color';
import zIndex from '@/core/features/styles/z-index';
import Tooltip from '@/core/features/tooltip/tooltip';

import styles from './map-tooltip.module.scss';

type MapTooltipOption = {
    iconUrl?: string;
    isSelected: boolean;
    title: string;
};

type MapVerticaSwitchTooltipProps<OptionType extends MapTooltipOption> = {
    noOptionsText: null | string;
    onOptionClick: (option: OptionType) => void;
    options: OptionType[];
};

export default function MapTooltip<OptionType extends MapTooltipOption>({
    noOptionsText,
    onOptionClick,
    options,
}: MapVerticaSwitchTooltipProps<OptionType>) {
    const { isDesktopView } = useDeviceoutput();
    return (
        <div className={styles.tooltip}>
            <Tooltip
                content={
                    <TooltipContent
                        noOptionsText={noOptionsText}
                        onOptionClick={onOptionClick}
                        options={options}
                    />
                }
                tippyConfig={{
                    arrow: false,
                    offset: isDesktopView ? [-18, 54] : [20, 50],
                    placement: 'right-end',
                }}
                visible={true}
            />
            <div
                className={`absolute ${styles.mapTooltipArrow}`}
                style={{ zIndex: zIndex('mapTooltipArrow') }}
            >
                <ChevronSolidIcon color={color('white')} />
            </div>
        </div>
    );
}

const TooltipContent = <OptionType extends MapTooltipOption>({
    noOptionsText,
    onOptionClick,
    options,
}: {
    noOptionsText: null | string;
    onOptionClick: (option: OptionType) => void;
    options: OptionType[];
}) => {
    return (
        <ul className={`${styles.list}`}>
            {options.length === 0 && noOptionsText ? (
                <li className={`${styles.option}`}>
                    <span className={'font-size-14'}>{noOptionsText}</span>
                </li>
            ) : (
                options.map((option, index) => (
                    <React.Fragment key={`${option.title}-${index}`}>
                        <li
                            className={`${styles.option} flex-start-horizontal gap-10 pointer width-100`}
                            data-qa-id={'qa-map-tooltip-option'}
                            onClick={() => onOptionClick(option)}
                        >
                            <span className={'flex-start-horizontal gap-15 width-100'}>
                                {option.iconUrl && (
                                    <IconImage
                                        height={20}
                                        url={option.iconUrl}
                                        width={20}
                                    />
                                )}
                                <span className={'font-size-14 line-clamp-1'}>{option.title}</span>
                            </span>
                            {option.isSelected && (
                                <IconImage
                                    className={'align-self-end'}
                                    height={24}
                                    url={getCdnStaticImageUrl('/static-images/check-mark-blue.svg')}
                                    width={24}
                                />
                            )}
                        </li>
                        {index !== options.length - 1 && <hr className={styles.divider} />}
                    </React.Fragment>
                ))
            )}
        </ul>
    );
};
