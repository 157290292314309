import type { Placement } from '@/features/map/map-components/map-markers/map-marker-handler/map-tooltip/map-tooltip-service';

import React from 'react';

import { bemModule } from '@/core/utils/bem-classname';
import {
    calculatePlacement,
    getSpacing,
} from '@/features/map/map-components/map-markers/map-marker-handler/map-tooltip/map-tooltip-service';

import styles from './map-tooltip.module.scss';

type MapTooltipProps = React.PropsWithChildren & {
    anchorRef?: React.RefObject<HTMLDivElement | null>;
    overlayRef?: React.RefObject<HTMLDivElement | null>;
    placements: Array<Placement>;
    spacing?: { x: number; y: number } | number;
};

const bem = bemModule(styles);

export default function MapTooltip({ anchorRef, children, overlayRef, placements, spacing = 0 }: MapTooltipProps) {
    const calculationReadyRef = React.useRef(false);
    const initialRenderRef = React.useRef(true);
    const [rect, setRect] = React.useState<DOMRect | null>(null);
    const [placement, setPlacement] = React.useState<Placement>('bottom');

    React.useLayoutEffect(() => {
        if (!anchorRef?.current) {
            return;
        }

        const resizeObserver = new ResizeObserver(() => {
            calculationReadyRef.current = false;
            if (!anchorRef.current || !overlayRef?.current) {
                return;
            }
            setRect(anchorRef.current?.getBoundingClientRect());
            setPlacement(calculatePlacement(anchorRef.current, overlayRef.current, placements, spacing));
            calculationReadyRef.current = true;
            initialRenderRef.current = false;
        });
        resizeObserver.observe(anchorRef.current);

        return () => {
            calculationReadyRef.current = false;
        };
    }, [anchorRef, overlayRef, placements, spacing]);

    return (
        <div className={bem(styles.mapTooltip, { show: calculationReadyRef.current || !initialRenderRef.current })}>
            <TileContainer
                elementHeight={rect?.height ?? 0}
                elementWidth={rect?.width ?? 0}
                placement={placement}
                spacing={getSpacing(spacing, placement)}
            >
                {children}
            </TileContainer>
        </div>
    );
}

type StyledTileContainer = {
    children?: React.ReactNode;
    elementHeight: number;
    elementWidth: number;
    placement: Placement;
    spacing?: number;
};

const TileContainer = ({ children, elementHeight, elementWidth, placement, spacing }: StyledTileContainer) => {
    const transformValue = () => {
        switch (placement) {
            case 'top':
                return `translate(calc(-50% + ${elementWidth / 2}px), calc(-100% - ${spacing ?? 0}px))`;
            case 'bottom':
                return `translate(calc(-50% + ${elementWidth / 2}px), calc(${elementHeight}px + ${spacing ?? 0}px))`;
            case 'left':
                return `translate(calc(-100% - ${spacing ?? 0}px), calc(-50% + ${elementHeight / 2}px))`;
            case 'right':
                return `translate(calc(0% + ${elementWidth}px + ${spacing ?? 0}px), calc(-50% + ${
                    elementHeight / 2
                }px))`;
            default:
                return '';
        }
    };
    return (
        <div
            className={styles.tileContainer}
            style={{
                transform: transformValue(),
            }}
        >
            {children}
        </div>
    );
};
