import type { IMapSpotTile } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import { useClientUrl } from '@/core/features/app/app-atoms';
import MapTileWrapper from '@/features/map/map-components/map-tiles/map-tile-wrapper';
import { MapSpotTileTopLivePricesDesktop } from '@/features/map/map-components/map-tiles/spot-tile-desktop/map-spot-tile-top-live-prices/map-spot-tile-top-live-prices-desktop';
import useMapOverlayHistoryState from '@/features/map/map-container/use-map-overlay-history';
import SeasonCalendar from '@/features/season-calendar/season-calendar';
import { trackSpotResultClick } from '@/features/spot-results/spot-result-tracking';
import SpotScoreBadge from '@/features/spot-score/spot-score-badge';

type MapSpotTileProps = IMapSpotTile;

export default function MapSpotTileDesktop({
    calendar,
    destinationTitle,
    iconUrl,
    image,
    linkUrl,
    spotScore,
    spotTitle,
    topLivePrices,
}: MapSpotTileProps) {
    const [clientUrl] = useClientUrl();
    const { closeOverlay } = useMapOverlayHistoryState();

    const handleOnTileClick = (event: React.MouseEvent<HTMLAnchorElement, Event>) => {
        trackSpotResultClick(spotTitle, { isMap: true });

        //checking if the click happened on the same path (if that is the case, the map should be closed)
        if (linkUrl === clientUrl) {
            event.preventDefault();
            closeOverlay();
        }
    };

    return (
        <div
            className={'flex height-100'}
            data-qa-id={'qa-map-spot-tile-desktop'}
        >
            <MapTileWrapper
                cursorStyle={'pointer'}
                hasLivePrices={topLivePrices.length > 0}
                height={'100%'}
                iconUrl={iconUrl}
                image={image}
                isDesktopView={true}
                linkType={'internal'}
                linkUrl={linkUrl}
                onTileLinkClick={handleOnTileClick}
                subtitle={destinationTitle}
                title={spotTitle}
                titleTruncation={'line-clamp-1'}
            >
                <div className={'flex-column padding-left-15 padding-bottom-15'}>
                    <SpotScoreBadge
                        fontSizeScore={'font-size-14'}
                        fontSizeText={'font-size-14'}
                        height={32}
                        marginBottom={10}
                        scoreMarginRight={'margin-right-10'}
                        spotScore={{
                            htmlScoreDescription: spotScore.htmlScoreDescription,
                            scoreText: spotScore.scoreText,
                        }}
                        width={32}
                    />
                    <div className={'flex-column gap-5'}>
                        <SeasonCalendar
                            gapOverride={3}
                            rowCount={2}
                            seasonCalendar={calendar.seasonCalendar}
                            title={calendar.title}
                            titleFontSize={'font-size-14'}
                            titleLineHeight={'line-height-17'}
                            titleMarginBottom={5}
                            titleTruncation={'text-nowrap'}
                        />
                    </div>
                </div>
            </MapTileWrapper>

            {topLivePrices.length > 0 && (
                <div
                    className={'flex grow-1'}
                    style={{
                        // eslint-disable-next-line no-inline-styles/no-inline-styles
                        alignItems: 'stretch',
                        // eslint-disable-next-line no-inline-styles/no-inline-styles
                        minWidth: '85px',
                    }}
                >
                    <MapSpotTileTopLivePricesDesktop
                        livePrices={topLivePrices}
                        trackingTitle={spotTitle}
                    />
                </div>
            )}
        </div>
    );
}
