import type { IMapAccommodationTile } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import useLinkoutTracking from '@/features/linkout-tracking/use-linkout-tracking';
import { MapAccommodationMplTileDesktop } from '@/features/map/map-components/map-tiles/accommodation-tiles/accommodation-desktop/map-accommodation-mpl-tile-desktop';
import { MapAccommodationTileContent } from '@/features/map/map-components/map-tiles/accommodation-tiles/accommodation-desktop/map-accommodation-tile-content';
import MapTileWrapper from '@/features/map/map-components/map-tiles/map-tile-wrapper';

export type MapAccommodationTileDesktopProps = IMapAccommodationTile & { isLoading: boolean };

export default function MapAccommodationTileDesktop({
    accommodationType,
    accommodationTypeBadge,
    badges,
    highlightedBadges,
    image,
    isLoading,
    keyfacts,
    linkUrl,
    name,
    offer,
    overlay,
    rating,
    spotName,
    stars,
}: MapAccommodationTileDesktopProps) {
    const { trackLinkout } = useLinkoutTracking();
    const [isOverlayOpen, setOverlayOpen] = React.useState(false);
    const linkoutClickHandler = () => {
        trackLinkout({ elementType: 'accommodation_tile_map', spot: spotName, verticalType: accommodationType });
    };

    const toggleOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
        setOverlayOpen(!isOverlayOpen);
        event.stopPropagation();
    };

    const closeOverlay = () => {
        setOverlayOpen(false);
    };

    return (
        <MapTileWrapper
            height={'100%'}
            highlightedBadges={highlightedBadges}
            image={image}
            imageWidth={140}
            isDesktopView={true}
            linkType={'vertical'}
            linkUrl={overlay ? null : linkUrl} // workaround for non-nullable linkUrl. See [bc-15]
            onImageClick={toggleOverlay}
            onTileLinkClick={linkoutClickHandler}
            qaId={'qa-map-accommodation-tile-desktop'}
            target={'_blank'}
            width={'486px'}
        >
            {overlay ? (
                <MapAccommodationMplTileDesktop
                    accommodationTypeBadge={accommodationTypeBadge}
                    closeOverlay={closeOverlay}
                    isOverlayOpen={isOverlayOpen}
                    name={name}
                    overlay={overlay}
                    spotName={spotName}
                    toggleOverlay={toggleOverlay}
                >
                    <MapAccommodationTileContent
                        accommodationTypeBadge={accommodationTypeBadge}
                        badges={badges}
                        highlightedBadges={highlightedBadges}
                        isLoading={isLoading}
                        keyfacts={keyfacts}
                        name={name}
                        offer={offer}
                        rating={rating}
                        stars={stars}
                    />
                </MapAccommodationMplTileDesktop>
            ) : (
                <MapAccommodationTileContent
                    accommodationTypeBadge={accommodationTypeBadge}
                    badges={badges}
                    highlightedBadges={highlightedBadges}
                    isLoading={isLoading}
                    keyfacts={keyfacts}
                    name={name}
                    offer={offer}
                    rating={rating}
                    stars={stars}
                />
            )}
        </MapTileWrapper>
    );
}
