import { IconImage } from '@/core/features';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './map-layer-switch-option.module.scss';

const bem = bemModule(styles);

type MapLayereSelectionOptionProps = {
    active: boolean;
    imageUrl: string;
    label: string;
    onOptionClick: () => void;
};

export default function MapLayerSelectionOption({
    active,
    imageUrl,
    label,
    onOptionClick,
}: MapLayereSelectionOptionProps) {
    return (
        <div
            className={styles.mapTypeSelectOption}
            data-qa-id={'qa-map-layer-switch-option'}
            onClick={onOptionClick}
        >
            <IconImage
                alt={''}
                className={bem(styles.mapTypeSelectOptionImage, { active })}
                height={67}
                url={imageUrl}
                width={67}
            />
            <span className={bem(styles.mapTypeSelectTitle, { active })}>{label}</span>
        </div>
    );
}
