import type { AccommodationStarsType } from '@/features/accommodation-tiles/accommodation-type';

import React from 'react';

import Rating from '@/core/features/rating/rating';

type HotelStarsProps = {
    accommodationStars: AccommodationStarsType | null;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    tooltipText?: string;
};

export default function AccommodationTileStarsDesktop({ accommodationStars, onClick, tooltipText }: HotelStarsProps) {
    if (!accommodationStars || accommodationStars.count <= 0) {
        return null;
    }
    return (
        <div
            className={'flex'}
            onClick={onClick}
        >
            <Rating
                rating={accommodationStars.count}
                size={14}
                tooltipText={tooltipText}
                type={accommodationStars.type}
            />
        </div>
    );
}
