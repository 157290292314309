type ToolTipDirection = 'horizontal' | 'vertical';
export type Placement = 'bottom' | 'left' | 'right' | 'top';

export const calculatePlacement = (
    anchor: Element,
    overlay: Element | null,
    placements: Array<Placement>,
    spacing: { x: number; y: number } | number,
): Placement => {
    if (!overlay || !anchor) {
        return placements[0] ?? 'top';
    }

    const clippingContainer = findClippingParent(overlay);

    if (!clippingContainer) {
        return placements[0] ?? 'top';
    }

    const clippingContainerRect = clippingContainer.getBoundingClientRect();
    const overlayRect = overlay.getBoundingClientRect();
    const anchorRect = anchor.getBoundingClientRect();

    let secondBestPlacement: Placement | null = null;

    // find a completely visible placement in preferred order defined by the user
    // if none was found, use second-best placement (with one intersection)
    // eslint-disable-next-line fp/no-loops
    for (const placement of placements) {
        const computedSpacing = getSpacing(spacing, placement);
        switch (placement) {
            case 'bottom': {
                if (intersectsBottom(anchorRect, overlayRect, clippingContainerRect, computedSpacing, 'vertical')) {
                    break;
                }
                if (
                    intersectsLeft(anchorRect, overlayRect, clippingContainerRect, computedSpacing, 'vertical') ||
                    intersectsRight(anchorRect, overlayRect, clippingContainerRect, computedSpacing, 'vertical')
                ) {
                    if (!secondBestPlacement) {
                        // eslint-disable-next-line fp/no-mutation
                        secondBestPlacement = placement;
                    }
                    break;
                }
                return placement;
            }
            case 'top': {
                if (intersectsTop(anchorRect, overlayRect, clippingContainerRect, computedSpacing, 'vertical')) {
                    break;
                }
                if (
                    intersectsLeft(anchorRect, overlayRect, clippingContainerRect, computedSpacing, 'vertical') ||
                    intersectsRight(anchorRect, overlayRect, clippingContainerRect, computedSpacing, 'vertical')
                ) {
                    if (!secondBestPlacement) {
                        // eslint-disable-next-line fp/no-mutation
                        secondBestPlacement = placement;
                    }
                    break;
                }
                return placement;
            }
            case 'left': {
                if (intersectsLeft(anchorRect, overlayRect, clippingContainerRect, computedSpacing, 'horizontal')) {
                    break;
                }
                if (
                    intersectsTop(anchorRect, overlayRect, clippingContainerRect, computedSpacing, 'horizontal') ||
                    intersectsBottom(anchorRect, overlayRect, clippingContainerRect, computedSpacing, 'horizontal')
                ) {
                    if (!secondBestPlacement) {
                        // eslint-disable-next-line fp/no-mutation
                        secondBestPlacement = placement;
                    }
                    break;
                }
                return placement;
            }
            case 'right': {
                if (intersectsRight(anchorRect, overlayRect, clippingContainerRect, computedSpacing, 'horizontal')) {
                    break;
                }
                if (
                    intersectsTop(anchorRect, overlayRect, clippingContainerRect, computedSpacing, 'horizontal') ||
                    intersectsBottom(anchorRect, overlayRect, clippingContainerRect, computedSpacing, 'horizontal')
                ) {
                    if (!secondBestPlacement) {
                        // eslint-disable-next-line fp/no-mutation
                        secondBestPlacement = placement;
                    }
                    break;
                }
                return placement;
            }
        }
    }

    // If no completely visible placement was found, return the next best placement (with one intersection)
    return secondBestPlacement ?? placements[0] ?? 'top';
};

export const getSpacing = (spacing: { x: number; y: number } | number, placement: Placement): number => {
    if (typeof spacing === 'number') {
        return spacing;
    }

    if (placement === 'bottom' || placement === 'top') {
        return spacing.y;
    }

    return spacing.x;
};

const intersectsTop = (
    anchorRect: DOMRect,
    overlayRect: DOMRect,
    containerRect: DOMRect,
    spacing: number,
    direction: ToolTipDirection,
): boolean => {
    const isHorizontal = direction === 'horizontal';
    const heightModifier = isHorizontal ? 0.5 : 1;
    const positionOffset = isHorizontal ? anchorRect.height * 0.5 : 0;
    const spacingModifier = isHorizontal ? 0 : 1;
    return (
        anchorRect.y + positionOffset - spacing * spacingModifier - overlayRect.height * heightModifier <
        containerRect.y
    );
};

const intersectsBottom = (
    anchorRect: DOMRect,
    overlayRect: DOMRect,
    containerRect: DOMRect,
    spacing: number,
    direction: ToolTipDirection,
): boolean => {
    const isHorizontal = direction === 'horizontal';
    const heightModifier = isHorizontal ? 0.5 : 1;
    const spacingModifier = isHorizontal ? 0 : 1;
    return (
        anchorRect.y +
            anchorRect.height * heightModifier +
            spacing * spacingModifier +
            overlayRect.height * heightModifier >
        containerRect.y + containerRect.height
    );
};

const intersectsLeft = (
    anchorRect: DOMRect,
    overlayRect: DOMRect,
    containerRect: DOMRect,
    spacing: number,
    direction: ToolTipDirection,
): boolean => {
    const isVertical = direction === 'vertical';
    const widthModifier = isVertical ? 0.5 : 1;
    const positionOffset = isVertical ? anchorRect.width * 0.5 : 0;
    const spacingModifier = isVertical ? 0 : 1;
    return (
        anchorRect.x + positionOffset - spacing * spacingModifier - overlayRect.width * widthModifier < containerRect.x
    );
};

const intersectsRight = (
    anchorRect: DOMRect,
    overlayRect: DOMRect,
    containerRect: DOMRect,
    spacing: number,
    direction: ToolTipDirection,
): boolean => {
    const isVertical = direction === 'vertical';
    const widthModifier = isVertical ? 0.5 : 1;
    const spacingModifier = isVertical ? 0 : 1;
    return (
        anchorRect.x +
            anchorRect.width * widthModifier +
            spacing * spacingModifier +
            overlayRect.width * widthModifier >
        containerRect.x + containerRect.width
    );
};

const findClippingParent = (element: Element): Element | null => {
    if (getComputedStyle(element).overflow === 'hidden' || element.localName === 'body') {
        return element;
    }

    if (element.parentElement === null) {
        return null;
    }

    return findClippingParent(element.parentElement as Element);
};
