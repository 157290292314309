import type { CtaListOnClickInterface } from '@/core/components/cta-list/cta-list';
import type { AccommodationOverlayType } from '@/features/accommodation-tiles/accommodation-type';

import CtaListOverlay from '@/core/components/cta-list/cta-list-overlay';
import { atom } from '@/core/features/store/atom-store';
import useLinkoutTracking from '@/features/linkout-tracking/use-linkout-tracking';

export const mapAccommodationTileOverlayOpenAtom = atom<{
    overlay: AccommodationOverlayType;
    spotName: string;
} | null>(null);

type Props = {
    onClose: () => void;
    overlay: AccommodationOverlayType;
    spotName: string;
};

export default function MapAccommodationTileOverlay({ onClose, overlay, spotName }: Props) {
    const { trackLinkout } = useLinkoutTracking();

    const onOverlayCtaClick: CtaListOnClickInterface = (event, cta) => {
        event.stopPropagation(); // do not close overlay

        trackLinkout({ elementType: 'accommodation_tile_overlay_map', spot: spotName, verticalType: cta.type });
    };

    return (
        <CtaListOverlay
            {...overlay}
            badge={overlay.accommodationTypeBadge}
            linkType={'vertical'}
            onClick={onOverlayCtaClick}
            onClose={onClose}
            subtitleFontSize={'font-size-14'}
        />
    );
}
