import type { GoogleMapProps } from '@/features/map/google-map/google-map';

import React from 'react';

// eslint-disable-next-line import-rules/disallow-paths
import { Wrapper } from '@googlemaps/react-wrapper';

import { NEXT_PUBLIC_GOOGLE_MAPS_KEY } from '@/constants/env';
import GoogleMap from '@/features/map/google-map/google-map';

type GoogleMapWrapperProps = React.PropsWithChildren<GoogleMapProps>;

const GoogleMapWrapper_ = ({ children, ...props }: GoogleMapWrapperProps) => {
    if (!NEXT_PUBLIC_GOOGLE_MAPS_KEY) {
        throw 'NEXT_PUBLIC_GOOGLE_MAPS_KEY is missing';
    }

    return (
        <Wrapper apiKey={NEXT_PUBLIC_GOOGLE_MAPS_KEY}>
            <GoogleMap {...props}>{children}</GoogleMap>
        </Wrapper>
    );
};

export default React.memo(GoogleMapWrapper_);
