import type { IMapMultiPin, IMapPoiIconRecord } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import IconImage from '@/core/features/image-v2/icon-image';

type MultiPinMarkerProps = {
    activePoiKey: null | string;
    icons: IMapPoiIconRecord;
    marker: IMapMultiPin;
    markerRef?: React.RefObject<HTMLDivElement>;
    onClick: (event: React.MouseEvent, poiKey: string) => void;
};

export default function MultiPinMarker({ activePoiKey, icons, marker, markerRef, onClick }: MultiPinMarkerProps) {
    const { isActive, pins } = React.useMemo(() => {
        const pins = Object.entries(marker.pins);
        return { isActive: pins.some(([poiKey]) => poiKey === activePoiKey), pins };
    }, [activePoiKey, marker.pins]);

    return (
        <div
            className={`flex-center ${isActive ? 'gap-20' : ''}`}
            ref={markerRef}
        >
            {pins.map(([poiKey, poi], index) => {
                const isActive = activePoiKey === poiKey;
                const icon = icons?.[poi.pinIcon]?.[isActive ? 'active' : 'inactive'] ?? null;

                if (!icon) {
                    return null;
                }

                return (
                    <div
                        className={'flex-center pointer'}
                        data-qa-id={'qa-map-multi-marker'}
                        key={`${poi.poiId}-${index}`}
                        onClick={(event) => onClick(event, poiKey)}
                        // eslint-disable-next-line no-inline-styles/no-inline-styles
                        style={{ margin: '0 -8px', zIndex: poi.zPosition }}
                    >
                        <IconImage
                            height={icon.size}
                            url={icon.url}
                            width={icon.size}
                        />
                    </div>
                );
            })}
        </div>
    );
}
